import React from 'react'
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom'
import logo from '../Logo/tnb-logo 1.png'
import { logoutUser } from '../store/actions/logout';

function Header(props) {

    const handleLogout = () => {
        props.logout();
        
    }

    return (
        <div className="shadow-sm">
            <div className="p-3">
                <img src={logo} alt="Logo" style={{width:"150px", height:"50px"}}/>
            </div>
 
            <div className="d-flex justify-content-between px-3">
                <ul className="d-flex navBar">
                    <NavLink exact to="/" className="nav-link text-dark">Dashboard</NavLink>
                    <NavLink to="/inventory" className="nav-link text-dark" activeStyle={{background:"#d3d3d3"}}>Inventory</NavLink>
                    <NavLink to="/orders" className="nav-link text-dark" activeStyle={{background:"#d3d3d3"}}>Orders</NavLink>
                    <NavLink to="/coupons" className="nav-link text-dark" activeStyle={{background:"#d3d3d3"}}>Coupons</NavLink>
                    <NavLink to="/users" className="nav-link text-dark" activeStyle={{background:"#d3d3d3"}}>Users</NavLink>
                </ul>
                <div>
                    <button className="btn btn-secondary" onClick={handleLogout}>Sign Out</button>
                </div>
            </div>

        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout : () => dispatch(logoutUser())
    }
}

export default connect(null,mapDispatchToProps)(Header)