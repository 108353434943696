import React, {useState, useEffect} from 'react'
import { Button, Form, FormControl } from 'react-bootstrap'
import { connect } from 'react-redux';
import {login} from '../store/actions/login'
import url from '../urls.json'

function Login(props) {

    const [mobile, setMobile] = useState('');
    const [error, setError] = useState(false);
    const [otp, setOTP] = useState('');
    const [otpSent, setotpSent] = useState(false);
    const [otpsessionId, setOtpsessionId] = useState('');
    const [OTPVerifError, setOTPVerifError] = useState(false);
    const [OTPMsg, setOTPMsg] = useState('');
 
    const handleSubmit = () => {
        if (["9665975788","9766970669","7767885222","8087200785","8390400740","7744869109"].includes(mobile)){
            fetch(`${url.url}/api/auth/otp`,{
                method : "POST",
                headers:{
                    'Content-Type': 'application/json'
                },
                body : JSON.stringify({
                    phone:mobile
                })
            })
            .then(res => {
                if (res.status === 200) return res.json()
                console.log("Failed to ..");
                throw new Error("failed to ..");
            })
            .then(resJson => {
                if (resJson.error){
                    return;
                }else{
                    console.log(resJson)
                    setOtpsessionId(resJson.otpSessionID)
                    setotpSent(true)
                    setError(false)
                }
            })
            .catch(error => {
                console.log("Error ..", error);
            })
        
            console.log("this is admin")
        }else{
            setError(true)
        }
    }

    const handleChange = (e) =>{
        setMobile(e.target.value)
    }

    const handleVerify = () => {
        fetch(`${url.url}/api/auth/otp/verify`, {
            method : 'POST',
            headers: {
                'Content-Type': 'application/json'
              },
            body : JSON.stringify({
                otp : otp,
                otpSessionID : otpsessionId,
                phone : mobile
            })

        })
        .then(res => res.json())
        .then((resJson) => {
            console.log("Verification process complete", resJson);
            if (resJson.status === "Success"){
                console.log("Verification status success", resJson);
                props.login(mobile)
                // setOTPVerifError(false)
                
                // this.setState({loading : false, OTPVerifError : false, OTPError : false})
            }
            if(resJson.status === "Failure"){
                console.log("Verification status failure", resJson);
                setOTPVerifError(true)
                setOTPMsg("OTP did not match")
                // this.setState({loading : false, OTPVerifError : true, OTPErrMsg : "OTP did not match"})
            }
            if(resJson.status === "Expired"){
                console.log("Verification status failure", resJson);
                setOTPVerifError(true)
                setOTPMsg("OTP Expired")
                // this.setState({loading : false, OTPVerifError : true , OTPErrMsg : "OTP Expired"})
            }
        })
    }

    const handleReset = () => {
        setMobile('');
        setOTP('');
        setError(false);
        setOtpsessionId('');
        setotpSent(false);
    }
    return (
        <div className="m-5">
            <h3><center>The Nature's Bowl Admin Portal</center></h3>
            <h4 className="mb-4">Login using OTP</h4>
            {otpSent 
                    ?
                        <div>
                            <p>OTP sent to {mobile}</p>
                            <FormControl style={{width:"20%"}} type="text" placeholder="Enter OTP" onChange={e=>setOTP(e.target.value)}/>
                            <Button className="my-2" onClick={handleVerify}>Verify</Button>
                            <div className="mt-4">
                                <u className="mr-4 text-info" role="button" onClick={handleSubmit}>resend OTP</u>
                                <u className="text-info" role="button" onClick={handleReset}>change number</u></div>
                        </div>  
                    :
                        <div>
                            <FormControl style={{width:"20%"}} type="text" placeholder="Enter Mobile number" onChange={handleChange}/>
                            <Button className="my-2" onClick={handleSubmit}>Submit</Button>
                        </div>
            }
                     
            {error 
                ?
                    <div className="alert alert-danger" style={{maxWidth:"20%"}} role="alert">
                        Not an admin!
                    </div>
                :
                    null
            }
        </div>
    )
}
const mapDispatchToProps = (dispatch) => {
    return {
        login : (mobile) => dispatch(login(mobile))
    }
}
export default connect(null,mapDispatchToProps)(Login)
