import React, {useState, useEffect} from 'react'
import { Dropdown, DropdownButton, Table } from 'react-bootstrap'
import { updateStatus } from '../apis/updateOrderStatus'
import url from '../urls.json'

export default function Orders() {

    const [ordersData, setOrdersData] = useState([])
    const [orders, setOrders] = useState([])
    const [orderFilter, setOrdersFilter] = useState("confirmed")
    const [searchQuery, setSearchQuery] = useState("");
    const [searchBy, setSearchBy] = useState("user");
    

    useEffect(()=>{
        fetch(`${url.url}/api/orders`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
            })
            .then(res => {
                if (res.status === 200) return res.json()
                console.log("Failed to fetch products");
                throw new Error("failed to fetch products");
            })
            .then(resJson => {
                if (resJson.error){
                    window.alert("Error fetching orders from DB! Please check your connection or contact dev team")
                }else{
                    resJson.Items.sort(function(a,b){
                        return new Date(b.createdAt) - new Date(a.createdAt)
                    })
                    setOrdersData(resJson.Items)
                    setOrders(resJson.Items)
                }
            })
            .catch(error => {
                console.log("Error ..", error);
                window.alert("Error fetching orders from DB! Please check your connection or contact dev team")
            })
    },[])

    useEffect(()=>{
        console.log("Order Filter Changed", orderFilter)
        if(orderFilter === "all"){
            setOrders(ordersData)
        }else{
            setOrders(ordersData.filter(item => item.status === orderFilter))
        }
    }, [ordersData,orderFilter])

    useEffect(()=>{
        if(searchBy === "user"){
            setOrders(ordersData.filter(item=>{
                const name = item.PK
                return name.includes(searchQuery)
            }))
        }else{
            setOrders(ordersData.filter(item=> item.SK.includes(searchQuery)))
        }
    },[searchQuery])
    const handleStatusChange = (e,item) => {
        const status = e.target.value
        if(status.length > 0){
            console.log("status changed", status, item.SK)
            updateStatus(item, status)
        }
    }

    const orderItems = orders.map((item,index)=> {
        const date = new Date(item.createdAt)
        const months = {
            0 : "Jan", 1:"Feb", 2:"Mar", 3:"April", 4:"May", 5:"June", 
            6:"July", 7: "Aug", 8:"Sept", 9:"Oct", 10:"Nov", 11:"Dec"
        }
        const status = item.status
        const items = item.items.map((product,index)=>{
            return(
                <div className="d-flex justify-content-between p-2" key={index}>
                    <div className="p-1">{`${product.name} - ${product.size} ${product.unit}`}</div>
                    <div className="p-1">x</div>
                    <div className="p-1">{product.qty}</div>
                </div>
                
            )
        })
        return (
            <tr key={index}>
                <td>{index + 1}</td>
                <td>{date.getDate()}-{months[date.getMonth()]}-{date.getFullYear()}</td>
                <td>{item.SK}</td>
                <td className="text-capitalize font-weight-bold">
                    {status}
                    <select className="mt-5" id="orderStatus" onChange={e=>handleStatusChange(e,item)}>
                        <option value="">Change Status</option>
                        <option value="shipped">Shipped</option>
                        <option value="delivered">Delivered</option>
                        <option value="canceled">Canceled</option>
                        <option value="returned">Returned</option>
                        <option value="confirmed">Confirmed</option>
                    </select>
                </td>
                <td>{item.paymentMethod === "POSTPAID" ? "COD" : "PAID"}</td>
                <td>{item.PK}</td>
                <td>{item.address.fullName}</td>
                <td>{item.address.mobile}</td>
                <td>{item.address.email}</td>
                <td>{items}</td>
                <td>{item.total}</td>
                <td>{item.coupon}</td>
                <td>{item.address.line1}, {item.address.line2}, {item.address.city}, {item.address.state}, {item.address.pincode}</td>
            </tr>
        )
    })


    return (
        <div className="jumbotron">
            <div className="d-flex justify-content-between m-3">
                <div>
                    <select className="mr-3" id="searchBy" value={searchBy} onChange={e=>setSearchBy(e.target.value)}>
                        <option value="user">Search by User</option>
                        <option value="orderId">Search by OrderId</option>
                    </select>
                    <input onChange={e=>setSearchQuery(e.target.value)} placeholder="search .."></input>
                </div>
                <div>
                    <select id="orderFilter" value={orderFilter} onChange={e=>setOrdersFilter(e.target.value)}>
                        <option value="all">All Orders</option>
                        <option value="shipped">Shipped Orders</option>
                        <option value="delivered">Delivered Orders</option>
                        <option value="canceled">Canceled Orders</option>
                        <option value="returned">Returned Orders</option>
                        <option value="confirmed">Confirmed Orders</option>
                        <option value="pending">Pending Orders</option>
                    </select>
                </div>
            </div>
            <Table className="mt-5" bordered hover striped variant="dark" responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Order Id</th>
                        <th>Status</th>
                        <th>Payment Mode</th>
                        <th>UserID</th>
                        <th>Name</th>
                        <th>Mobile</th>
                        <th>Email</th>
                        <th>
                            Items
                        </th>
                        <th>Total</th>
                        <th>Promo Code</th>
                        <th>Address</th>
                    </tr>
                </thead>
                <tbody>
                    {orderItems}
                </tbody>
            </Table>
        </div>
    )
}
