import React from 'react';
import logo from './logo.svg';
import {Switch, Router, Route} from 'react-router-dom';
import './App.css';
import Login from './components/Login';
import { connect } from 'react-redux';
import Home from './components/Home';

function App(props) {
  return (
    <div>
      {props.authenticated
        ?
          <Home />
        :
          <Login />
      }
      {/* <Home /> */}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    authenticated : state.auth.authenticated
  }
}
export default connect(mapStateToProps)(App)
