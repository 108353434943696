import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import Coupons from './coupons/Coupons'
import Inventory from './inventory/Inventory'
import Header from './Header'
import Users from './Users'
import Dashboard from './Dashboard'
import Orders from './Orders'

export class Home extends Component {
    render() {
        return (
            <div>
                <Header />
                <Switch>
                    <Route exact path='/' component={Dashboard}/>
                    <Route path='/inventory' component={Inventory}/>
                    <Route path='/orders' component={Orders}/>
                    <Route path='/coupons' component={Coupons}/>
                    <Route path='/users' component={Users}/>
                </Switch>
            </div>
        )
    }
}

export default Home
